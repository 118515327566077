import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-router-dom/cjs/react-router-dom'
const PublicationCard = (props) => {
  const { id, title, authors, year, location, confTitle, abstract } = props
  return (
    <Card className='card-pub'>
      <Card.Header className='card-title'>
        <div className='pub-type'>{props.type}</div>[{confTitle}]- {title}{' '}
      </Card.Header>

      <Card.Body>
        <Tabs
          defaultActiveKey={1}
          id='uncontrolled-tab-example'
          className='mb-3'
          variant='pills'
        >
          <Tab
            className='myClass'
            key={1}
            eventKey={1}
            id='paper'
            title='Paper Info'
          >
            <div>
              <i className='fas fa-users px-1 fa-xs'></i>{' '}
              {authors.toString().replace(/,/g, ', ')}
            </div>
            <div className='py-1 '>
              <i class='fa-solid fa-location-dot fa-xs px-2'></i> {location}
            </div>
            <div className='py-1 info-bottom'>
              <div>
                {' '}
                <Link className='text-link-card'>
                  <i class='fa-solid fa-file-pdf fa-xs px-2'></i>Pdf |{' '}
                </Link>
              </div>
              <div>
                <Link className='text-link-card'>
                  <i class='fa-solid fa-database fa-xs px-2'></i>Data |{' '}
                </Link>
              </div>
              <div>
                <Link className='text-link-card'>
                  <i class='fa-brands fa-github fa-xs px-2'></i>Github
                </Link>
              </div>
            </div>
          </Tab>
          <Tab eventKey={2} title='Abstract' className='abstract'>
            {abstract}
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  )
}

export default PublicationCard
