import React, { useState, useEffect } from 'react'
import PublicationCard from '../utils/PublicationCard'
import { Button, Card, Container, Image, Row, Col } from 'react-bootstrap'
import publications from '../data/publications.json'
import TeamMemberCard from '../utils/TeamMemberCard'
import NewsCard from '../utils/NewsCard'
import BgVideo from '../resources/bgVideo.mp4'
import Header from '../utils/Header'
import NewsScreen from './NewsScreen'
import ResourcesScreen from './ResourcesScreen'
import vid from '../resources/video2.mp4'
import { motion } from 'framer-motion'
import ResourceCard from '../utils/ResourceCard'
import demo from '../resources/demo.png'
import fed from '../resources/fedweb.png'
import cyber from '../resources/cyber.png'
import iot from '../resources/iot.png'
import adapive from '../resources/adaptive.jpg'
import cyberphys from '../resources/cyberphysical.png'
import Carousel from 'react-bootstrap/Carousel'
import nash from '../resources/nashville.jpg'
import ahmed from '../resources/ahmed.jpg'
import csci from '../resources/csci.png'
import fig2 from '../resources/fig2.png'
import genai from '../resources/genai.jpg'
import resources from '../data/resources.json'
import sec from '../resources/sec.png'
import inter from '../resources/inter.png'
import FL from '../resources/FL.png'
import flamingo from '../resources/flamingo.png'
import flid from '../resources/flid.png'
import drone from '../resources/drone.png'
import interdependent from '../resources/interdependent.png'
import FLmain from '../resources/FLmain.png'
import cs1 from '../resources/cs1.png'
import internetwork from '../resources/internetwork.png'
import gene from '../resources/gene.png'
import gesture from '../resources/gesture.png'
import traffic from '../resources/traffic.png'
import fedavo from '../resources/fedavo.png'
import cyberattack from '../resources/cyberattack.png'
import toy from '../resources/imteaj_toy.jpg'
import toy2 from '../resources/imteaj_toy2.jpeg'

// import ExampleCarouselImage from 'components/ExampleCarouselImage'
import { useMediaQuery } from 'react-responsive'
import Footer from '../utils/Footer'
import tapia from '../resources/tapia.jpg'
import vid3 from '../resources/vid3.mp4'
import knife from '../resources/Crimedetection.png'
import Imteaj from '../resources/Dr-Imteaj.jpg'
import Crimewatchgpt from '../resources/Crimewatchgpt.png'
import realtriumph from '../resources/realtriumph.JPG'
import zarif_fellowship from '../resources/zarif_fellowship.jpeg'
import nsf_logo from '../resources/nsf.png'

import dhs_logo from '../resources/dhs.png'
import dhs_seal from '../resources/dhs_seal.png'
import nsf_seal from '../resources/nsf_seal.png'
import vlm from '../resources/vlm_.jpeg'
import vlm_hs from '../resources/vlm_hs.jpeg'
import ds from '../resources/ds.jpeg'
import AI from '../resources/AI.jpeg'

import topres from '../resources/topres.jpg'

const HomeScreen = () => {
  const [hovered, setHovered] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const [counter, setCounter] = useState(2)
  const HanldeCheck = (index) => {
    setHovered(!hovered)
  }
  const generateRandomNumber = (min, max) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min

    if (randomNumber % 2 == 0 && randomNumber > 5000) {
      setCounter(3)
    } else if (randomNumber % 2 != 0 && randomNumber < 5000) {
      setCounter(2)
    } else {
      setCounter(1)
    }
  }

  useEffect(() => {
    generateRandomNumber(1, 10000)
  }, [])

  const Topics = (props) => {
    return (
      <div>
        <Card className='card-topic'>
          <Card.Img className='top-img' variant='top' src={props.srcs} />
          <Card.Body>
            <Card.Title className='titletopic' style={{ fontSize: '1.2em' }}>
              {props.titles}
            </Card.Title>
            <Card.Text style={{ textAlign: 'center' }}>{props.text}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    )
  }

  const Featured = (props) => {
    return (
      <div>
        <Card className='card-feature'>
          <Card.Img className='top-img p-2' variant='top' src={props.src} />
          <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text>{props.desc}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    )
  }

  function whichvid() {
    if (counter == 1) {
      return BgVideo
    }
    if (counter == 2) {
      return BgVideo
    } else {
      return BgVideo
    }
  }
  return (
    <div className='bg-overlay mt-3'>
      <Header home={true} />
      <div className='landingpage'>
        <video
          className='video-bg'
          src={whichvid()}
          autoPlay
          muted
          loop
        ></video>
        <div className='home-text'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
            className={isTabletOrMobile ? 'welcome-mob' : 'welcome'}
          >
            Edge Secure. Privacy Intact. Intelligence Unleashed
            {/* <br /> OUR */}
            <br />
            {/* SPEED LAB */}
          </motion.div>
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ delay: 1, duration: 1 }}
            className={isTabletOrMobile ? 'slogan-mob' : 'slogan'}
          >
            {/* Security, Privacy & intElligence for Edge Devices */}
            Speeding Towards Trustworthy Innovation!
          </motion.div>

          <div
            className='playbut'
            onClick={() => {
              HanldeCheck()
            }}
          >
            <i
              style={{ color: 'white' }}
              className={
                hovered
                  ? 'fa-regular fa-circle-play fa-2xl'
                  : 'fa-regular fa-circle-pause fa-2xl'
              }
            ></i>
          </div>
        </div>
      </div>

      <Container className='homepage-container'>
        {/* <Image fluid src={demo} /> */}
        <motion.div
          initial={{ x: -10000 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          className='top-page'
        >
          {/* <h1 className='my-4'>General Research Topics</h1>
          <hr /> */}
          <Row className='top-row'>
            <Row className='top-row'>
              <Col xs={13} md={7} lg={4}>
                <Card className='card-topic'>
                  <Card.Img
                    style={{ padding: '10px' }}
                    className='top-img'
                    variant='top'
                    src={FLmain}
                  />
                  <Card.Body>
                    <Card.Title
                      className='titletopic'
                      style={{ fontSize: '1.2em', color: 'white' }}
                    >
                      <span style={{ color: '#04B4AE' }}>
                        Federated Learning
                      </span>
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center', color: 'FFA500' }}>
                      {/* Fueling Transformative Intelligence: Efficiently
                      Empowering Edge Devices */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={13} md={7} lg={4}>
                <Card className='card-topic'>
                  <Card.Img
                    style={{ padding: '10px' }}
                    className='top-img'
                    variant='top'
                    src={vlm_hs}
                  />
                  <Card.Body>
                    <Card.Title
                      className='titletopic'
                      style={{ fontSize: '1.2em', color: 'white' }}
                    >
                      <span style={{ color: 'green' }}>LLMs and VLMs</span>
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center', color: 'FFA500' }}>
                      {/* Understanding and generating visual and texual data */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} md={6} lg={4}>
                <Card
                  style={{ backgroundColor: 'white' }}
                  className='card-topic'
                >
                  <Card.Img
                    style={{ padding: '0px' }}
                    className='top-img'
                    variant='top'
                    src={cyberattack}
                  />
                  <Card.Body>
                    <Card.Title
                      className='titletopic-m'
                      style={{ fontSize: '1.2em', color: 'black' }}
                    >
                      <span style={{ color: '#6495ED' }}>CyberSecurity</span>{' '}
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center', color: 'Black' }}>
                      {/* Establishing Robust Defense Mechanism for the Detection
                      and Prevention of Cyber Threats */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} md={6} lg={4}>
                <Card className='card-topic'>
                  <Card.Img
                    style={{ paddingTop: '0px' }}
                    className='top-img'
                    variant='top'
                    src={AI}
                  />
                  <Card.Body>
                    <Card.Title
                      className='titletopic'
                      style={{ fontSize: '1.2em', color: 'white' }}
                    >
                      <span style={{ color: '#9F81F7' }}>
                        Artificial Intelligence{' '}
                      </span>{' '}
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center' }}>
                      {/* Unleashing Collective Intelligence through Interdependent
                      Decision-Making */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Card className='card-topic'>
                  <Card.Img
                    style={{ paddingTop: '0px' }}
                    className='top-img'
                    variant='top'
                    src={interdependent}
                  />
                  <Card.Body>
                    <Card.Title
                      className='titletopic'
                      style={{ fontSize: '1.2em', color: 'white' }}
                    >
                      <span style={{ color: '#868A08' }}>
                        Interdependent Networks{' '}
                      </span>{' '}
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center' }}>
                      {/* Unleashing Collective Intelligence through Interdependent
                      Decision-Making */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={13} md={7} lg={4}>
                <Card className='card-topic'>
                  <Card.Img
                    style={{ paddingTop: '0px' }}
                    className='top-img'
                    variant='top'
                    src={ds}
                  />
                  <Card.Body>
                    <Card.Title
                      className='titletopic'
                      style={{ fontSize: '1.2em', color: 'white' }}
                    >
                      <span style={{ color: '#B40431' }}>Data Science </span>{' '}
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center' }}>
                      {/* Unleashing Collective Intelligence through Interdependent
                      Decision-Making */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Row>
        </motion.div>
        <motion.div
          initial={{ x: -10000 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <h1 className='my-4'>Vision</h1>
          {/* <hr /> */}
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2, duration: 1.2 }}
        >
          <p>
            At the <b>Security, Privacy, and Intelligence for Edge Devices (SPEED) Lab  </b>, 
              our vision is to drive transformative research in the domain of AI, federated learning, 
              large language models, vision-language models, cybersecurity, and multi-modality. We strive to place strong emphasis on edge
            intelligence, tailoring the potential of distributed computing to
            enable efficient decision-making. We aspire to pioneer advancements that redefine the boundaries of AI and machine learning, while ensuring the security and privacy of distributed systems. 
            Through our interdisciplinary research, we strive to develop robust, efficient, and intelligent solutions that enhance decision-making in edge environments, driving innovation that positively impacts society in an increasingly interconnected world.
          </p>
        </motion.div>
        <motion.div
          initial={{ x: -10000 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <h4 className='my-4'>Our research is supported by</h4>
          {/* <hr /> */}

          <Row className='justify-content-center align-items-center'>
            <Col
              xs={6}
              md={6}
              className='d-flex justify-content-end align-items-center pe-2'
            >
              <img
                src={nsf_seal}
                alt='NSF Logo'
                className='img-fluid'
                style={{ maxWidth: '60%' }}
              />
            </Col>
            <Col
              xs={6}
              md={6}
              className='d-flex justify-content-start align-items-center ps-2'
            >
              <img
                src={dhs_seal}
                alt='DHS Logo'
                className='img-fluid'
                style={{ maxWidth: '55%' }}
              />
            </Col>
          </Row>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2, duration: 1.2 }}
        ></motion.div>
        <motion.div
          initial={{ x: -10000 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <Container>
            <motion.div
              initial={{ x: -10000 }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <h1 className='my-4'>Highlights</h1>
              <hr />
            </motion.div>{' '}
            <Carousel
              className='my-3'
              style={{
                height: '450px',
                display: 'flex',
                justifyContent: 'center',
              }}
              data-bs-theme='dark'
            >
              <Carousel.Item>
                <img className='carousel-img' src={toy2} alt='First slide' />
              </Carousel.Item>

              <Carousel.Item>
                <img className='carousel-img' src={Imteaj} alt='Second slide' />
                <Carousel.Caption className='caption'>
                  <p>
                    NSF CRII Grant of $167,500 Awarded to Dr. Imteaj! Thanks to
                    NSF!
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='carousel-img'
                  src={zarif_fellowship}
                  alt='Third slide'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img className='carousel-img' src={ahmed} alt='Fourth slide' />
                <Carousel.Caption className='caption'>
                  <p>
                    Dr. Imteaj’s project with FIU won nearly $100,000 DHS grant!
                    {/*  It'll boost education in AI's role in both committing
                and combating cybercrime. */}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img className='carousel-img' src={nash} alt='Fifth slide' />
                <Carousel.Caption className='caption'>
                  {/* <h5>First slide label</h5> */}
                  <p>Zarif presented paper at IEEE SmartComp!</p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img className='carousel-img' src={tapia} alt='Sixth slide' />
                <Carousel.Caption className='caption'>
                  <p>
                    Saika won the Graduate Research Prize at Tapia'23
                    Conference.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className='carousel-img'
                  src={realtriumph}
                  alt='Second slide'
                />
                <Carousel.Caption className='caption'>
                  {/* <h5>First slide label</h5> */}
                  <p>
                    Dr. Imteaj received the Prestigious FIU Real Triumph Award!
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img className='carousel-img' src={csci} alt='Forth slide' />
                <Carousel.Caption className='caption'>
                  <p>Best Paper Award at IEEE CSCI conference! </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className='carousel-img' src={topres} alt='Forth slide' />
                <Carousel.Caption className='caption'>
                  <p>
                    Dr. Ahmed Imteaj Named in Stanford’s Top 2% Scientists for
                    Research Impact{' '}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Container>

          {/* <h1 className='my-4'>Anouncement</h1> */}
          {/* <hr /> */}
        </motion.div>{' '}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <div className='anouncement p-2'>
            {/* <i class='fa-solid fa-bullhorn'></i> */}

            <p>
              <b>
                {' '}
                Anouncement: A fully funded Ph.D. position is available! We are
                looking for strongly motivated candidates having experience in
                Large Language Models, Federated Learning or Computer Vision. We are always looking forward to work with SIUC undergraduated and MS students.
              </b>
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ x: -10000 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          {/* <hr /> */}
        </motion.div>{' '}
        <h1 className='my-4'>Featured Projects</h1>
        {/* <h1 className='my-vlm'></h1> */}
        {isTabletOrMobile ? (
          <Col xs={12} md={6} lg={6}>
            <Featured
              title='Developing a Robust and Accurate Visual Language Model'
              desc=' Our project focuses on advancing the field of visual language modeling by developing a state-of-the-art model that can accurately interpret and generate natural 
              language descriptions for visual content. Through innovative techniques and deep learning architectures, we aim to create a model that not only improves the understanding
               of visual data but also enhances the generation of meaningful and contextually relevant textual descriptions. 
              This research has applications in image captioning, visual question answering, and various other tasks that require a deep understanding of both visual and textual data.'
              src={vlm}
            />
          </Col>
        ) : (
          <div className='row'>
            <div className='col-12 mt-3'>
              <div className='card-topic'>
                <div className='card p-2'>
                  <div className='card-horizontal'>
                    <div className='img-square-wrapper p-3'>
                      <img
                        className='img-container3'
                        src={vlm}
                        alt='Card image cap'
                      />
                    </div>
                    <div className='card-body'>
                      <h5>Ongoing Project</h5>
                      <h4 className='card-title'>
                        Developing Robust and Accurate Visual Language Model
                      </h4>
                      <p className='card-text'>
                        Our project focuses on developing visual language model
                        that can accurately interpret and generate natural
                        language descriptions for visual content. Through
                        innovative techniques, we aim to create a model that not
                        only improves the understanding of visual data but also
                        enhances the generation of meaningful and contextually
                        relevant textual descriptions. This research has
                        applications in image captioning, visual question
                        answering, and various other tasks that require a deep
                        understanding of both visual and textual data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isTabletOrMobile ? (
          <Col xs={12} md={6} lg={6}>
            <Featured
              title='SkyWatch: Unveiling Criminal Activity via Keypoint Analysis with Video-Streaming of Drones (Aerial Perspectives)'
              desc='This research delves into the realm of crime detection through a pioneering integration of drone technology and developing a novel keypoint detection algorithm. 
              The ongoing project focuses on the acquisition of criminal activity data using drones and the application of advanced keypoint detection algorithms to decipher intricate patterns and anomalies. 
              The overarching goal of this initiative is to provide law enforcement agencies with a powerful tool to elevate their situational awareness and proactively address criminal behavior, leveraging a unique aerial perspective. 
              By combining the capabilities of drones and cutting-edge algorithms, 
              this project seeks to contribute to the advancement of crime detection methodologies, offering law enforcement a strategic advantage in identifying and responding to criminal activities with greater efficiency and precision.'
              src={knife}
            />
          </Col>
        ) : (
          <div className='row'>
            <div className='col-12 mt-3'>
              <div className='card-topic'>
                <div className='card p-2'>
                  <div className='card-horizontal'>
                    <div className='img-square-wrapper p-3'>
                      <img
                        className='img-container2'
                        src={knife}
                        alt='Card image cap'
                      />
                    </div>
                    <div className='card-body'>
                      <h5>FEATURED</h5>
                      <h4 className='card-title'>
                        SkyWatch: Unveiling Criminal Activity via Keypoint
                        Analysis with Video-Streaming of Drones (Aerial
                        Perspectives)
                      </h4>
                      <p className='card-text'>
                        This research delves into the realm of crime detection
                        through a pioneering integration of drone technology and
                        developing a novel keypoint detection algorithm. The
                        ongoing project focuses on the acquisition of criminal
                        activity data using drones and the application of
                        advanced keypoint detection algorithms to decipher
                        intricate patterns and anomalies. The overarching goal
                        of this initiative is to provide law enforcement
                        agencies with a powerful tool to elevate their
                        situational awareness and proactively address criminal
                        behavior, leveraging a unique aerial perspective. By
                        combining the capabilities of drones and cutting-edge
                        algorithms, this project seeks to contribute to the
                        advancement of crime detection methodologies, offering
                        law enforcement a strategic advantage in identifying and
                        responding to criminal activities with greater
                        efficiency and precision.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Row>
          {/* {resources.map((record) => (
            <Col xs={12} md={3} lg={6}></Col>
          ))} */}

          <Col xs={12} md={6} lg={6}>
            <Featured
              title='FLAMINGO: Adaptive and Resilient Federated Meta-Learning against
  Adversarial Attacks'
              desc='Federated Meta-Learning (FML) can produce personalized model with limited training data which is suitable for resource-constrained edge devices. Nonetheless, the FML process may susceptible to adversarial attacks.
              This project delves into the dynamic landscape of FML under adversarial attacks and propose a novel FML algorithm, 
              FLAMINGO that enhance meta-learners 
              robustness against adversarial attacks and prevent overfitting through adversarial meta-training and consistency regularization, all while minimizing communication cost.'
              src={flamingo}
            />
          </Col>
          {/* <Col xs={12} md={6} lg={6}>
            <Featured
              title='Skyward Insights: Drone-Powered Land Cover and Land Use Detection'
              desc='This project leverages federated learning to analyze aerial images obtained via drone surveillance. Through collaborative learning across individual drones, the project aspires to develop a sophisticated system capable of real-time analysis, precise identification, and classification of land cover and land use patterns. The envisioned system holds vast potential for applications across diverse domains including environmental monitoring, urban planning, agriculture, disaster response, and beyond, offering invaluable insights for informed decision-making and resource management based on detailed land use assessments.'
              src={drone}
            />
          </Col> */}
          <Col xs={12} md={6} lg={6}>
            <Featured
              title='WatchOverGPT: A Framework for Real-Time Crime Detection and Response Using Wearable Camera and Large Language Models'
              desc='The aim of this project is to promptly identify and respond to potential life-threatening
              criminal activities and safety concerns. WatchOverGPT combines
              the capabilities of wearable cameras, smartphones’ location data,
              and large language model (LLM)-based advanced conversational
              AI communication through ChatGPT. Through real-time
              data processing and AI-driven autonomous communication, the
             framework aims to bridge the gap between safety
              threats and emergency response teams in the fight against crime
              and enhance the security of individuals in various settings'
              src={Crimewatchgpt}
            />
          </Col>
          {}
        </Row>
        {isTabletOrMobile ? (
          <Col xs={12} md={6} lg={6}>
            <Featured
              title='FLID: Intrusion Attack and Defense Mechanism for
              Federated Learning Empowered Connected Autonomous
              Vehicles (CAVs) Application'
              desc='Connected autonomous vehicles (CAVs) are trans- forming
              the transportation business by incorporating advanced
              technology such as sensors, communication systems, and
              artificial intelligence. However, the interconnectedness
              and complexity of CAVs pose security vulnerabilities,
              making them possible targets for assaults.'
              src={fig2}
            />
          </Col>
        ) : (
          <div className='row'>
            <div className='col-12 mt-3'>
              <div className='card-topic'>
                <div className='card p-2'>
                  <div className='card-horizontal'>
                    <div className='img-square-wrapper p-3'>
                      <img
                        className='img-container'
                        src={fedavo}
                        alt='Card image cap'
                      />
                    </div>
                    <div className='card-body'>
                      <h5>Ongoing Project</h5>
                      <h4 className='card-title'>
                        Robust, Lightweight and Secure Distributed Machine
                        Learning Algorithms
                      </h4>
                      <p className='card-text'>
                        Our research focus revolves around developing
                        cutting-edge Robust, Lightweight, and Secure Distributed
                        Machine Learning Algorithms. Emphasizing robust
                        intelligence, our algorithms dynamically adapt to
                        diverse data distributions, ensuring consistent
                        performance in dynamic settings. Prioritizing
                        lightweight solutions, our goal is to enhance efficiency
                        without compromising accuracy, ideal for
                        resource-constrained environments. Exploring the realm
                        of securing distributed learning, our research aims to
                        establish a robust foundation for trustworthy and
                        resilient machine learning applications in diverse and
                        evolving scenarios.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Row>
          {/* {resources.map((record) => (
            <Col xs={12} md={3} lg={6}></Col>
          ))} */}

          {/* <Col xs={12} md={6} lg={6}>
            <Featured
              title='Learning across Interdependent Cyber-Physical-Societal Networks'
              desc='This project is dedicated to develop a tailored Distributed Machine Learning Framework designed specifically for interconnected Cyber-Physical-Societal Networks. Our focus is on enabling interdependent decision-making processes by extracting profound insights from the interconnected networks. This framework aims to comprehend the complex relationships between these networks, facilitating a deeper understanding of their interdependence and empowering informed decision-making for enhanced resilience and efficiency across interconnected systems.'
              src={internetwork}
            />
          </Col> */}
          {/* <Col xs={12} md={6} lg={6}>
            <Featured
              title='Unveiling the Interplay: Generative AIs Realm in Cybersecurity and Ethical Frontiers'
              desc='Navigating the evolving landscape of Generative AI, such as GPT models, signifies a critical imperative in our technological advancement. 
              However, their vulnerability to cyber threats and the potential for unethical misuse pose significant concerns for society. Our emphasis is on revealing the intricate relationship between Generative AI, cybersecurity, and ethical considerations. This exploration aims to comprehend how Generative AI operates within the realm of cybersecurity while conscientiously navigating the ethical frontiers associated with its application.'
              src={gene}
            />
          </Col> */}

          {/* {resources.map((record) => (
            <Col xs={12} md={3} lg={6}></Col>
          ))} */}

          <Col xs={12} md={6} lg={6}>
            <Featured
              title='Skyward Insights: Drone-Powered Land Cover and Land Use Detection'
              desc='This project leverages federated learning to analyze aerial images obtained via drone surveillance. Through collaborative learning across individual drones, 
              the project aspires to develop a sophisticated system capable of real-time analysis, precise identification, and classification of land cover and land use patterns. 
              The envisioned system holds vast potential for applications across diverse domains including environmental monitoring, urban planning, agriculture, disaster response, and beyond.'
              src={drone}
            />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <Featured
              title='Driving Smarter Roads: Real-time Traffic Insights through Federated Learning and Social Media Analysis'
              desc='This project pioneers a cutting-edge approach to traffic management by leveraging Federated Learning and Social Media Analysis for real-time insights. The model refines its understanding of dynamic traffic patterns without centralized data aggregation. The integration of social media data provides contextual information, enriching the accuracy of real-time traffic assessments.  Ultimately, the project envisions a smarter road network with real-time analytics for efficient traffic management.'
              src={traffic}
            />
          </Col>
        </Row>
      </Container>
      {/* <div className='ongoing-w'>
        <Container className='ong-research'>
          <div className={isTabletOrMobile ? 'col-ct' : 'row-ct'}>
            <div className='colimg'>
              <div className='img-rs'>
                <Image className='img-rs' src={fedavo} fluid />
              </div>
            </div>
            <div className='coltxt'>
              <div className='ong-txt'>
                <h3 className='ong-txt-h3-bl'>
                  {' '}
                  Robust, Lightweight and Secure Distributed Machine Learning
                  Algorithms{' '}
                </h3>
                <p
                  className='p-1'
                  style={{ color: 'black', fontWeight: '380' }}
                >
                  Our research focus revolves around developing cutting-edge
                  Robust, Lightweight, and Secure Distributed Machine Learning
                  Algorithms. Emphasizing robust intelligence, our algorithms
                  dynamically adapt to diverse data distributions, ensuring
                  consistent performance in dynamic settings. Prioritizing
                  lightweight solutions, our goal is to enhance efficiency
                  without compromising accuracy, ideal for resource-constrained
                  environments. Exploring the realm of securing distributed
                  learning, our research aims to establish a robust foundation
                  for trustworthy and resilient machine learning applications in
                  diverse and evolving scenarios.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div> */}

      <Container>
        {' '}
        <motion.div
          initial={{ x: -10000 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <h1 className='my-4'>Ongoing Research</h1>
          {/* <hr /> */}
        </motion.div>{' '}
      </Container>
      <div className='ongoing'>
        <Container className='ong-research'>
          <div className={isTabletOrMobile ? 'col-ct' : 'row-ct'}>
            <div className='coltxt'>
              <div className='ong-txt'>
                <h3 className='ong-txt-h3'>
                  {' '}
                  Responsible Development and Deployment of Generative AI{' '}
                </h3>
                <p
                  className='p-1'
                  style={{ color: 'white', fontWeight: '400' }}
                >
                  The responsible development and deployment of Generative AI
                  (Artificial Intelligence) is a critical imperative as this
                  technology continues to advance. Generative AI, particularly
                  exemplified by models like GPT (Generative Pre-trained
                  Transformer), has demonstrated remarkable capabilities in
                  generating human-like text, images, and more. However, with
                  great power comes great responsibility. Our focus in
                  responsible development lies in ethical considerations,
                  transparency, and mitigating potential risks.
                </p>
              </div>
            </div>
            <div className='colimg'>
              <div className='img-rs'>
                <Image className='img-rs' src={gene} fluid />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className='ongoing-w'>
        <Container className='ong-research'>
          <div className={isTabletOrMobile ? 'col-ct' : 'row-ct'}>
            <div className='colimg'>
              <div className='img-rs'>
                <Image className='img-rs' src={internetwork} fluid />
              </div>
            </div>
            <div className='coltxt'>
              <div className='ong-txt'>
                <h3 className='ong-txt-h3-bl'>
                  {' '}
                  Learning across Interdependent Cyber-Physical-Societal
                  Networks{' '}
                </h3>
                <p
                  className='p-1'
                  style={{ color: 'black', fontWeight: '400' }}
                >
                  This project is dedicated to develop a tailored Distributed
                  Machine Learning Framework designed specifically for
                  interconnected Cyber-Physical-Societal Networks. Our focus is
                  on enabling interdependent decision-making processes by
                  extracting profound insights from the interconnected networks.
                  This framework aims to comprehend the complex relationships
                  between these networks, facilitating a deeper understanding of
                  their interdependence and empowering informed decision-making
                  for enhanced resilience and efficiency across interconnected
                  systems.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className='ongoing'>
        <Container className='ong-research'>
          <div className={isTabletOrMobile ? 'col-ct' : 'row-ct'}>
            <div className='coltxt'>
              <div className='ong-txt'>
                <h3 className='ong-txt-h3'>
                  {' '}
                  Drone Swarming, Distributed Streaming and Learning
                </h3>
                <p
                  className='p-1'
                  style={{ color: 'white', fontWeight: '400' }}
                >
                  This project involves orchestrating a fleet of DJI Tello
                  drones to operate collectively. Through distributed streaming,
                  these drones exchange real-time data and insights. This data
                  sharing facilitates a collaborative learning environment where
                  each drone contributes its observations and experiences to a
                  shared knowledge base. This collective intelligence enables
                  the swarm to exhibit synchronized behavior and adaptive
                  decision-making, enhancing their ability to navigate complex
                  environments, perform tasks efficiently, and respond
                  dynamically to changing scenarios.
                </p>
              </div>
            </div>
            <div className='colimg'>
              <div className='img-rs'>
                <Image className='img-rs' src={gesture} fluid />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default HomeScreen
