import React, { useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import director from '../resources/Imteaj.jpeg'

import ListGroup from 'react-bootstrap/ListGroup'
const TeamMemberCard = () => {
  const [hovered, setHovered] = useState([false, false, false, false, false])
  const toggleHover = () => setHovered(!hovered)
  const HanldeCheck = (index) => {
    setHovered((prevState) =>
      prevState.map((item, idx) => (idx === index ? !item : item))
    )
  }
  return (
    <Card className='card-team-adv'>
      <Card.Header>
        <div className='avatar-img'>
          <Image
            roundedCircle
            className='avatar-big-imt rounded-circle'
            src={director}
          />
        </div>

        <Card.Title style={{ fontWeight: 'bold' }} className='text-center my-1'>
          Dr. Ahmed Imteaj
          <p className='muted'>Assistant Professor (Tenure-track )</p>
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Card.Text style={{ color: 'black' }}>
        Ahmed Imteaj is a tenure-track Assistant Professor of Computer Science at 
        Southern Illinois University Carbondale, and the founding director of the Security, Privacy, and 
        Intelligence for Edge Devices Lab (www.speedlab.network). 
        Imteaj received his Ph.D. in Computer Science from Florida International University in 2022 with the 
        prestigious FIU Real Triumph Graduate Award and received his MS in Computer Science from FIU with the recognition of 
        the Outstanding Master’s Degree Graduate award. Prior to that, he received his B.Sc. degree in Computer Science and Engineering from Chittagong University of Engineering and Technology. 
        His research interests encompass Federated Learning, Visual-Language Models (VLMs), Large-Language Models (LLMs), Cybersecurity, Artificial Intelligence, and Multi-modality.  Application domains include safe and smart cities, transportation networks, healthcare, and energy systems.
        Since joining SIUC in Fall 2022, Dr. Imteaj has secured two federal grants—NSF CRII and DHS CINA, was recognized with the Outstanding Teacher of the Year Award and developed two new courses on Generative AI. Imteaj has published more than 70 refereed journal and conference papers, and book chapters. He is the recipient of the best paper award from “2019 IEEE Conference on Computational Science & Computational Intelligence” and the lead author of the book, "Foundations of Blockchain: Theory and Applications".
        </Card.Text>
     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className='button'
            variant='primary'
            href='https://sites.google.com/view/ahmedimteaj'
          >
            Learn More{' '}
            <i className='fa-solid fa-arrow-right-long fa icon-right '></i>
          </Button>
        </div>
      </Card.Body>
      <div className='card-row'>
        <Row className='scholar'>
          <Col>
            <a
              onMouseEnter={() => {
                HanldeCheck(0)
              }}
              onMouseLeave={() => {
                HanldeCheck(0)
              }}
              target='_blank'
              className='link'
              href={'https://sites.google.com/view/ahmedimteaj'}
            >
              <i
                className={
                  hovered[0]
                    ? 'fa-solid fa-house fa-2xl fa-beat-fade'
                    : 'fa-solid fa-house fa-2xl'
                }
              ></i>
            </a>
          </Col>
          <Col>
            <a
              onMouseEnter={() => {
                HanldeCheck(1)
              }}
              onMouseLeave={() => {
                HanldeCheck(1)
              }}
              target='_blank'
              className='link'
              href={
                'https://scholar.google.com/citations?user=I5IZ4GQAAAAJ&hl=en'
              }
            >
              <i
                className={
                  hovered[1]
                    ? 'ai ai-google-scholar-square fa-beat-fade ai-2x '
                    : 'ai ai-google-scholar-square ai-2x'
                }
              ></i>
            </a>
          </Col>
          <Col>
            <a
              onMouseEnter={() => {
                HanldeCheck(2)
              }}
              onMouseLeave={() => {
                HanldeCheck(2)
              }}
              target='_blank'
              className='link'
              href={'https://www.linkedin.com/in/ahmed-imteaj-ph-d-a13343a3/'}
            >
              <i
                className={
                  hovered[2]
                    ? 'fa-brands fa-linkedin  fa-2xl  fa-beat-fade '
                    : 'fa-brands fa-linkedin fa-2xl'
                }
              ></i>
            </a>
          </Col>
          <Col>
            <a
              onMouseEnter={() => {
                HanldeCheck(3)
              }}
              onMouseLeave={() => {
                HanldeCheck(3)
              }}
              target='_blank'
              className='link'
              href={'https://www.researchgate.net/profile/Ahmed-Imteaj'}
            >
              <i
                className={
                  hovered[3]
                    ? 'fa-brands fa-researchgate  fa-beat-fade fa-2xl'
                    : 'fa-brands fa-researchgate fa-2xl'
                }
              ></i>
            </a>
          </Col>
          <Col>
            <a
              onMouseEnter={() => {
                HanldeCheck(4)
              }}
              onMouseLeave={() => {
                HanldeCheck(4)
              }}
              target='_blank'
              className='link'
              href={'https://orcid.org/0000-0002-6975-3997'}
            >
              <i
                className={
                  hovered[4]
                    ? 'fa-brands fa-orcid fa-2xl fa-beat-fade '
                    : 'fa-brands fa-orcid fa-2xl'
                }
              ></i>
            </a>
          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default TeamMemberCard
