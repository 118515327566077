import React from 'react'
import Card from 'react-bootstrap/Card'
import { NavLink, Link } from 'react-router-dom/cjs/react-router-dom'
import { Button, Row, Col } from 'react-bootstrap'
import director from '../resources/achievement.png'

const NewsCard = (props) => {
  const style = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 3,
    maxWidth: '100%',
  }

  const MyComponent = ({ inputString }) => {
    const hasPTag = inputString.includes('<p>')

    const content = hasPTag ? (
      <p dangerouslySetInnerHTML={{ __html: inputString }} />
    ) : (
      <p>{inputString}</p>
    )

    return <div>{content}</div>
  }

  return (
    <Link className='text-link-card'>
      <Card className='card-news'>
        <Card.Body>
          <div style={{ fontSize: '1rem', color: '#3e3e3e' }}>{props.type}</div>
          <Card.Title> {props.title}</Card.Title>
          <Card.Subtitle className='my-2 mb-2 text-muted'>
            <i style={{ color: '#258493' }} class='px-1 fas fa-calendar'></i>{' '}
            {props.date}
          </Card.Subtitle>
          <Card.Text className='truncated-text' style={style}>
            <MyComponent inputString={props.news.toString()} />
          </Card.Text>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/news/${props.id}`}>
              <Button className='my-1 button' variant='primary'>
                Learn More{' '}
                <i className='fa-solid fa-arrow-right-long fa icon-right '></i>
              </Button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Link>
  )
}

export default NewsCard
