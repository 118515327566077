import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <div style={{ width: '100%' }}>
        <div
          className='text-center p-4 footer'
          style={{ backgroundColor: '#1c565e', color: 'white' }}
        >
          <Row>
            <Col>
              <h5>Contact Us</h5>
              <p>
              SIU School of Computing, EGRA 409E, 
                <br />
                1230 Lincoln Dr, Carbondale, IL 62901, USA
              </p>
            </Col>
            <Col>
              <h5 className='mb-2'>Connect</h5>

              <p>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    window.location.href = 'mailto:imteaj@cs.siu.edu'
                  }}
                  className='link'
                  // href={'href="mailto:info@mailgo.dev"'}
                >
                  <i
                    style={{ color: 'white' }}
                    class='fas fa-envelope  fa-2xl px-2'
                  ></i>{' '}
                </a>
                <a
                  target='_blank'
                  className='link'
                  href={'https://www.linkedin.com/in/speed-lab-a7a1422a2/'}
                >
                  <i
                    style={{ color: 'white' }}
                    class='fa-brands fa-linkedin  fa-2xl'
                  ></i>{' '}
                </a>
                <a
                  target='_blank'
                  className='link'
                  href={'https://github.com/speedlab-git'}
                >
                  <i
                    style={{ color: 'white' }}
                    class='fa-brands fa-github fa-2xl px-2'
                  ></i>{' '}
                  {/* <i class="fa-brands fa-github"></i> */}
                </a>
              </p>
              {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
              <i class='fa-brands fa-square-twitter'></i>
            </div> */}
            </Col>
          </Row>
          © 2024 Copyright: {'SPEED LAB'}
        </div>
      </div>
    </footer>
  )
}

export default Footer
