import React from 'react'
import PublicationCard from '../utils/PublicationCard'
import { Container } from 'react-bootstrap'
import publications from '../data/publications.json'
import Header from '../utils/Header'
import Footer from '../utils/Footer'
const PublicationScreen = () => {
  return (
    <div className='body'>
      <Header home={false} />
      <Container>
        {' '}
        <h1 className='my-4'>Publications </h1>
        <h3 style={{ marginBottom: '20px' }}>
          Journals and Conference Proceedings
        </h3>
        {/* <h4>2023</h4>
        <hr /> */}
        {publications.map((record) => (
          <PublicationCard
            id={record.id}
            title={record.title}
            authors={record.authors}
            year={record.year}
            location={record.location}
            confTitle={record.confTitle}
            abstract={record.abstract}
            type={record.type}
          />
        ))}
      </Container>
    </div>
  )
}

export default PublicationScreen
