import React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { LinkContainer } from 'react-router-bootstrap'
import logo from '../resources/logo.png'
import { motion } from 'framer-motion'
const Header = (props) => {
  return (
    <Navbar
      //   style={{ color: '#1c565e' }}
      className={props.home ? 'navbar-home' : 'navbar-others'}
      expand='lg'
      data-bs-theme='dark'
    >
      <Container>
        {props.home ? (
          <Navbar.Brand href='/'>
            <motion.div
              initial={{ x: -10000 }}
              animate={{ x: 0 }}
              transition={{ delay: 0.3, duration: 1 }}
            >
              <img src={logo} alt='Logo' height='75' />
            </motion.div>
          </Navbar.Brand>
        ) : (
          <Navbar.Brand href='/'>
            <img src={logo} alt='Logo' height='60' />
          </Navbar.Brand>
        )}

        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='justify-content-end flex-grow-1 pe-3'>
            <LinkContainer to='/' exact>
              <Nav.Link eventKey='1'>
                {' '}
                <i class='fa-solid fa-house px-1 fa-xs'></i>Home
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/publications' exact>
              <Nav.Link eventKey='2'>
                {' '}
                <i class='fa-regular fa-file-lines fa-xs px-1'></i>Publications
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to='/resources' exact>
              <Nav.Link eventKey='3'>
                {' '}
                <i class='fa-solid fa-microchip fa-xs px-1'></i>Resources
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to='/news' exact>
              <Nav.Link eventKey='4'>
                <i class='fa-solid fa-square-rss fa-xs px-1'></i>News
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to='/Team' exact>
              <Nav.Link eventKey='5'>
                <i class='fa-solid fa-user-group fa-xs px-1'></i>Team
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
