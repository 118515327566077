import React from 'react'
import { Card } from 'react-bootstrap'
const ResourceCard = (props) => {
  return (
    <Card className='card-rsc'>
      <Card.Header className='resource-img'>
        <Card.Img variant='top' src={props.img} className='rsc-big' />
      </Card.Header>

      <Card.Body>
        <Card.Title>{props.name}</Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
      </Card.Body>
    </Card>
  )
}

export default ResourceCard
