import React, { useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import director from '../resources/Imteaj.jpeg'
import { useCollapse } from 'react-collapsed'

const StudentCard = (props) => {
  const {
    name,
    position,
    interests,
    github,
    img,
    website,
    scholar,
    LinkedIn,
    researchGate,
    achievements,
  } = props
  const [hovered, setHovered] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  const toggleHover = () => setHovered(!hovered)
  const HanldeCheck = (index) => {
    setHovered((prevState) =>
      prevState.map((item, idx) => (idx === index ? !item : item))
    )
  }
  const IMG = (imgName) => {
    return require(`../resources/${imgName}`)
  }
  return (
    <Card className='card-team'>
      <Card.Header>
        <div className='avatar-img'>
          <Image
            roundedCircle
            className='avatar-big rounded-circle'
            src={IMG(img)}
          />
        </div>

        <Card.Title style={{ fontWeight: 'bold' }} className='text-center my-1'>
          {name}
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Card.Text className='bio'>
          <div className='studentrow'>
            <i
              style={{ color: '#258493' }}
              class='py-1 fa-solid fa-graduation-cap px-1'
            ></i>{' '}
            {position.toString()}
          </div>
        </Card.Text>
        <div className='studentrow '>
          <i
            style={{ color: '#258493' }}
            class='py-1 fa-solid fa-book-open px-1'
          ></i>{' '}
          <p>
            {' '}
            <b> Research Thesis/Project: </b> {interests.toString()}
          </p>
        </div>

        <div {...(achievements.length > 0 ? { ...getCollapseProps() } : null)}>
          <div>
            <i style={{ color: '#258493' }} class='py-1 fas fa-award px-1'></i>{' '}
            <b> Achievements: </b>
          </div>
          <p>
            {achievements.map((rec) => (
              <div>
                <i
                  style={{ color: '#258493' }}
                  class='ach fas fa-dot-circle fa-2xs px-1'
                ></i>{' '}
                {rec}
              </div>
            ))}
          </p>
        </div>

        <div className='read'>
          {achievements.length > 0 ? (
            <span className='readmore' {...getToggleProps()}>
              {isExpanded ? 'Read Less' : 'Read More...'}
            </span>
          ) : null}
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* <Button
            className='button'
            variant='primary'
            href='https://sites.google.com/view/ahmedimteaj'
          >
            Learn More{' '}
            <i className='fa-solid fa-arrow-right-long fa icon-right '></i>
          </Button> */}
        </div>
      </Card.Body>
      <div className='card-row'>
        <Row className='scholar'>
          {website ? (
            <Col>
              <a
                onMouseEnter={() => {
                  HanldeCheck(0)
                }}
                onMouseLeave={() => {
                  HanldeCheck(0)
                }}
                target='_blank'
                className='link'
                href={website}
              >
                <i
                  className={
                    hovered[0]
                      ? 'fa-solid fa-house fa-2xl fa-beat-fade'
                      : 'fa-solid fa-house fa-2xl'
                  }
                ></i>
              </a>
            </Col>
          ) : null}

          {scholar ? (
            <Col>
              <a
                onMouseEnter={() => {
                  HanldeCheck(1)
                }}
                onMouseLeave={() => {
                  HanldeCheck(1)
                }}
                target='_blank'
                className='link'
                href={scholar}
              >
                <i
                  className={
                    hovered[1]
                      ? 'ai ai-google-scholar-square fa-beat-fade ai-2x '
                      : 'ai ai-google-scholar-square ai-2x'
                  }
                ></i>
              </a>
            </Col>
          ) : null}

          {LinkedIn ? (
            <Col>
              <a
                onMouseEnter={() => {
                  HanldeCheck(2)
                }}
                onMouseLeave={() => {
                  HanldeCheck(2)
                }}
                target='_blank'
                className='link'
                href={LinkedIn}
              >
                <i
                  className={
                    hovered[2]
                      ? 'fa-brands fa-linkedin  fa-2xl  fa-beat-fade '
                      : 'fa-brands fa-linkedin fa-2xl'
                  }
                ></i>
              </a>
            </Col>
          ) : null}

          {researchGate ? (
            <Col>
              <a
                onMouseEnter={() => {
                  HanldeCheck(3)
                }}
                onMouseLeave={() => {
                  HanldeCheck(3)
                }}
                target='_blank'
                className='link'
                href={researchGate}
              >
                <i
                  className={
                    hovered[3]
                      ? 'fa-brands fa-researchgate  fa-beat-fade fa-2xl'
                      : 'fa-brands fa-researchgate fa-2xl'
                  }
                ></i>
              </a>
            </Col>
          ) : null}

          {github ? (
            <Col>
              <a
                onMouseEnter={() => {
                  HanldeCheck(4)
                }}
                onMouseLeave={() => {
                  HanldeCheck(4)
                }}
                target='_blank'
                className='link'
                href={github}
              >
                <i
                  className={
                    hovered[4]
                      ? 'fa-brands fa-github fa-2xl fa-beat-fade '
                      : 'fa-brands fa-github fa-2xl'
                  }
                ></i>
              </a>
            </Col>
          ) : null}

          {/* <Col>
            <a
              onMouseEnter={() => {
                HanldeCheck(5)
              }}
              onMouseLeave={() => {
                HanldeCheck(5)
              }}
              target='_blank'
              className='link'
              href={'https://orcid.org/0000-0002-6975-3997'}
            >
              <i
                className={
                  hovered[5]
                    ? 'fa-brands fa-github fa-2xl fa-beat-fade '
                    : 'fa-brands fa-github fa-2xl'
                }
              ></i>
            </a>
          </Col> */}
        </Row>
      </div>
    </Card>
  )
}
export default StudentCard
