import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ResourceCard from '../utils/ResourceCard'
import resources from '../data/resources.json'
import Header from '../utils/Header'

const ResourcesScreen = () => {
  return (
    <div className='body'>
      <Header home={false} />
      <Container>
        <h1 className='mt-4'>Resources</h1>
        <hr />
        <Row>
          {resources.map((record) => (
            <Col xs={12} md={6} lg={4}>
              <ResourceCard
                id={record.id}
                name={record.name}
                img={record.img}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ResourcesScreen
