import React, { useState, useEffect } from 'react'
import { Container, Image } from 'react-bootstrap'
import news from '../data/news.json'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Header from '../utils/Header'

const IndividualNewsScreen = ({ history, match }) => {
  const [myNews, setMyNews] = useState(null)
  const [loading, setLoading] = useState(false)

  const MyComponent = ({ inputString }) => {
    const hasPTag = inputString.includes('<p>')

    const content = hasPTag ? (
      <p dangerouslySetInnerHTML={{ __html: inputString }} />
    ) : (
      <p>{inputString}</p>
    )

    return <div>{content}</div>
  }

  function fetchNews(id) {
    setLoading(true)
    const result = news.find((item) => item.id == id)
    setMyNews(result)
    setLoading(false)
  }
  const IMG = (imgName) => {
    return require(`../resources/${imgName}`)
  }
  useEffect(() => {
    fetchNews(match.params.id)
  }, [])

  return (
    <div className='body'>
      <Header home={false} />

      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Spinner
            style={{
              alignItems: 'center',
              marginTop: '30rem',
              color: '#258493',
            }}
            animation='grow'
          />
        ) : myNews ? (
          <div className='news-top my-3'>
            <div className='goback'>
              <Link to={'/news'} className='goback-link'>
                {' '}
                <i
                  style={{ paddingRight: '5px' }}
                  class='fa-solid fa-chevron-left py-1'
                ></i>
                SPEED Lab News
              </Link>
            </div>
            <h1>{myNews.title.toString().toUpperCase()}</h1>
            {/* <div className='my-2 mb-2 text-muted'>
              <i class='px-1 fas fa-calendar'></i> July 12, 2023
            </div> */}
            {myNews.img ? (
              <div>
                <div className='my-4 imgcontainer'>
                  <Image className='newsimg' src={IMG(myNews.img)} />
                </div>
                <div className='news-text' style={{ textAlign: 'justify' }}>
                  <MyComponent inputString={myNews.news.toString()} />
                </div>
              </div>
            ) : (
              <div className='paragraph-container my-4'>
                <div
                  className='news-text my-3'
                  style={{ textAlign: 'justify' }}
                >
                  <MyComponent inputString={myNews.news.toString()} />
                </div>{' '}
              </div>
            )}
          </div>
        ) : (
          <Spinner animation='grow' />
        )}
      </Container>
    </div>
  )
}

export default IndividualNewsScreen
