import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import GrantsScreen from './screens/GrantsScreen'
import PublicationScreen from './screens/PublicationScreen'
import ResearchScreen from './screens/ResearchScreen'
import TeamsScreen from './screens/TeamsScreen'
import ResourcesScreen from './screens/ResourcesScreen'
import NewsScreen from './screens/NewsScreen'
import IndividualNewsScreen from './screens/IndividualNewsScreen'
import Header from './utils/Header'
import Footer from './utils/Footer'

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Route path='/' component={HomeScreen} exact />
      <Route path='/grants' component={GrantsScreen} exact />
      <Route path='/publications' component={PublicationScreen} exact />
      <Route path='/research' component={ResearchScreen} exact />
      <Route path='/team' component={TeamsScreen} exact />
      <Route path='/resources' component={ResourcesScreen} exact />
      <Route path='/teams/:id' component={ResourcesScreen} exact />
      <Route path='/news' component={NewsScreen} exact />
      <Route path='/news/:id' component={IndividualNewsScreen} exact />

      {/* 
        <Route path='/login' component={LoginScreen} exact />

        <Route path='/register' component={RegisterScreen} exact />
        <Route path='/forgotpassword' component={ForgotPasswordScreen} exact />



        <Route path='/add-product' component={PostScreen} exact />
        <Route path='/register/verify' component={VerifyScreen} exact />
        <Route path='/register/verified' component={VerifiedScreen} exact />

        <Route path='/contactus' component={ContactScreen} exact />
        <Route path='/editpost/:id' component={EditPostScreen} exact />

        <Route path='/profile/:id' component={UserProfileScreen} exact />
        <Route path='/editprofile' component={EditProfileScreen} exact /> */}
      <Footer />
    </Router>
  )
}

export default App
