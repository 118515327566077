import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import NewsCard from '../utils/NewsCard'
import news from '../data/news.json'
import Header from '../utils/Header'

const NewsScreen = () => {
  return (
    <div className='body'>
      <Header home={false} />
      <Container>
        {' '}
        <h1 className='my-4'>News </h1>
        <hr />
        {news.toReversed().map((n) => (
          <NewsCard
            title={n.title}
            id={n.id}
            date={n.date}
            news={n.news}
            type={n.type}
            img={n.img}
          />
        ))}
      </Container>
    </div>
  )
}

export default NewsScreen
